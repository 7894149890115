<template>
    <el-dialog
            :title="isSee?'查看采集结果':'编辑采集结果'"
            :visible.sync="dialogVisible"
            width="1024px"
            :close-on-click-modal="false"
            :before-close="handleClose">
        <el-form :model="editForm" :rules="editFormRules" ref="editForm" label-width="100px" class="demo-editForm">

            <el-form-item label="标题" prop="title" label-width="100px">
                <el-input v-model="editForm.title" autocomplete="off" ::disabled="isSee"></el-input>
            </el-form-item>
            <el-form-item label="网址" prop="title" label-width="100px">
                <el-input v-model="editForm.sourceUrl" autocomplete="off" :disabled="isSee"></el-input>
            </el-form-item>
            <el-form-item label="采集结果" prop="isAcquisitionSucceeded" label-width="100px" >
                <el-radio-group v-model="editForm.isAcquisitionSucceeded"  :disabled="isSee">
                    <el-radio :label="1">成功</el-radio>
                    <el-radio :label="2">失败</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="发布时间" prop="releaseTime" label-width="100px">
                <el-input v-model="editForm.releaseTime" autocomplete="off" :disabled="isSee"></el-input>
            </el-form-item>
            <el-form-item label="作者" prop="author" label-width="100px">
                <el-input v-model="editForm.author" autocomplete="off" :disabled="isSee"></el-input>
            </el-form-item>
            <el-form-item label="来源" prop="articleSource" label-width="100px">
                <el-input v-model="editForm.articleSource" autocomplete="off" :disabled="isSee"></el-input>
            </el-form-item>
            <el-form-item label="浏览量" prop="views" label-width="100px">
                <el-input v-model="editForm.views" autocomplete="off" :disabled="isSee"></el-input>
            </el-form-item>
            <el-form-item label="图片地址" prop="imgUrl" label-width="100px">
                <el-input v-model="editForm.imgUrl" autocomplete="off" :disabled="isSee"></el-input>
            </el-form-item>
            <el-form-item label="视频地址" prop="videoUrl" label-width="100px">
                <el-input v-model="editForm.videoUrl" autocomplete="off" :disabled="isSee"></el-input>
            </el-form-item>
            <el-form-item label="附件地址" prop="enclosureUrl" label-width="100px">
                <el-input v-model="editForm.enclosureUrl" autocomplete="off" :disabled="isSee"></el-input>
            </el-form-item>
            <el-form-item label="内容" label-width="100px" prop="content">
                <tinymce-editor v-model="editForm.publishingContent" :type="3"  @change="change" :disabled="isSee" ></tinymce-editor>
            </el-form-item>

            <el-form-item align="center">
                <el-button type="primary" @click="submitForm('editForm')" v-if="!isSee">保存</el-button>
                <el-button @click="resetForm('editForm')" >重置</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script>
    import TinymceEditor from "../inc/TinymceEditor";
    export default {
        name: "CollectionResultsInfo",
        components:{
            TinymceEditor
        },
        data(){
            return{
                editForm:{
                },
                dialogVisible:false,
                isSee:true,
                editFormRules: {},
            }
        },
        methods:{
            change(val) {
                this.editForm.publishingContent=val;
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.dialogVisible = false
                this.editForm = {}
            },
            handleClose() {
                this.resetForm('editForm')
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.$axios.post('/admin/reptile/updateReptileInfo', this.editForm)
                            .then(res => {

                                this.$message({
                                    showClose: true,
                                    message: '恭喜你，操作成功',
                                    type: 'success',
                                    onClose:() => {
                                        this.$emit('refreshDataList')
                                    }
                                });
                                this.dialogVisible = false
                                this.resetForm(formName)
                            })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            init(id,isSee){
                this.dialogVisible=true;
                this.isSee=isSee;
                if (id!=null&&id!=''&&id>0){
                    this.$axios.post('/admin/reptile/getReptileInfo' ,{reptileId:id}).then(res => {
                        this.editForm = res.data
                    })
                }
            },
        }
    }
</script>

<style scoped>
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
</style>
